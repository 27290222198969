import { emit } from "actions"
import { Buttons, Inputs } from "components"
import { PLOVDIV_COORDS, SOFIA_COORDS, cartTypes } from "config/constants"
import { useQuery } from "hooks"
import { omit, pick } from "lodash"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form"
import { Alerts, getDistanceFromLatLonInKm } from "utilities"
import { useNavigate } from "react-router-dom"
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import "./styles.scss"

const ReservationForm = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { _id } = useQuery()
    const locationRef = useRef(null)

    const { control, trigger, register, unregister, setValue, handleSubmit, formState: { errors }, reset, setError, clearErrors, watch } = useForm()
    const { fields, append, remove, } = useFieldArray({ control, name: "clientVehicles" })
    const { value: reservationType } = useWatch({ control, name: 'reservationType' }) || {}
    const { value: cartGroup } = useWatch({ control, name: 'cartGroup' }) || {}
    const clientVehicles = useWatch({ control, name: 'clientVehicles' })
    const city = useWatch({ control, name: 'city' })
    const date = useWatch({ control, name: 'date' }) || null
    const { value: reservationStart } = useWatch({ control, name: "reservationStart" }) || {}

    useEffect(() => {
        if (_id) emit({
            action: 'reservation/getOne', payload: { _id },
            startLoading: true, stopLoading: true,
            onSuccess: ({ reservation: { reservationType, cartGroup, city, clientVehicles, reservationStart, clientInfo, client, ...reservation } }) => {
                const email = clientInfo?.email || client?.email || null
                reset({
                    reservationType: { value: reservationType, label: t(cartTypes[reservationType]) },
                    cartGroup: { value: cartGroup?._id, label: cartGroup?.name },

                    date: moment(reservationStart).isSameOrAfter(moment(), 'day') ? reservationStart : null,
                    reservationStart: moment(reservationStart).isSameOrAfter(moment(), 'day') ? { value: reservationStart, label: moment(reservationStart).format("HH:mm") } : null,

                    city: city?.name,

                    client: client?._id,
                    clientInfo: {
                        fullName: clientInfo?.fullName || client?.fullName,
                        ...((clientInfo?.phoneNumber || client?.phoneNumber) && { phoneNumber: [clientInfo || client].reduce((_, { phoneNumber }) => ({ value: phoneNumber, label: phoneNumber }), {}), }),
                        email: {label:email, value:email, email},
                    },

                    clientVehicles: clientVehicles.map(({ licensePlate, carMake, vehicleType, services }) => ({
                        licensePlate,
                        carMake,
                        vehicleType: { value: vehicleType?._id, label: vehicleType?.name },
                        service: { value: services?.[0]?._id, label: services?.[0]?.name },
                    })),

                    ...pick(reservation, [
                        'address',
                        'addressDescription',
                        'location',

                        "paymentStatus",
                        "paymentDate",
                        "paymentMethod",
                        "stripeCustomerId",
                        "stripePaymentMethod",
                        "amarantPaymentIntent",
                        "discountCode",
                        "discountCodeRef",
                        "discountCampaign",
                        "organization",

                        "amarantId",
                        "geoWashClient",
                        "isManual",

                        "selectedPaymentMethod",
                        "status",
                    ])
                })
            }
        })
        else reset({ clientVehicles: [{}] })
    }, [_id, reset, setValue, t])

    const discountCampaign = useWatch({ control, name: 'discountCampaign' })
    const organization = useWatch({ control, name: 'organization' })

    const [cartGroups, setCartGroups] = useState([])
    const [vehicleTypes, setVehicleTypes] = useState([])
    const [services, setServices] = useState([])
    const [slots, setSlots] = useState([])
    useEffect(() => { emit({ action: 'cartGroup/browse', payload: { pagination: false }, onSuccess: ({ cartGroups }) => setCartGroups(cartGroups) }) }, [])
    useEffect(() => { emit({ action: 'vehicleType/list', onSuccess: ({ vehicleTypes, services }) => { setVehicleTypes(vehicleTypes); setServices(services) } }) }, [])
    useEffect(() => {
        if (reservationType
            && ((reservationType === 'mobile' && city) || cartGroup)
            && date
            && services.length
            && clientVehicles?.length && clientVehicles?.every(({ vehicleType, service }) => !!vehicleType && !!service)
        ) {
            const { duration } = formatPriceAndDuration(formatClientVehicles(clientVehicles), services)
            emit({
                action: 'reservation/findSlot',
                payload: { reservationType, cartGroup, date, duration, ignoreReservation: _id, city },
                onSuccess: ({ availableSlots }) => setSlots(availableSlots)
            })
        }
    }, [_id, services, reservationType, cartGroup, clientVehicles, date, city])


    useEffect(() => {
        if (services.length && clientVehicles?.length && clientVehicles?.every(({ vehicleType, service }) => !!vehicleType && !!service)) {
            const { priceWithoutDiscount, duration, price } = formatPriceAndDuration(formatClientVehicles(clientVehicles), services, discountCampaign, organization)
            setValue('priceWithoutDiscount', priceWithoutDiscount)
            setValue('duration', duration)
            setValue('price', price)
        } else {
            setValue('priceWithoutDiscount', null)
            setValue('duration', null)
            setValue('price', null)

        }
    }, [_id, services, cartGroup, clientVehicles, setValue, discountCampaign, organization])

    useEffect(() => {
        if (!slots.length || !reservationStart) return
        if (!slots.map((s) => moment(s).format()).includes(moment(reservationStart).format())) setValue("reservationStart", null)
    }, [reservationStart, setValue, slots])

    const formatPriceAndDuration = (selectedVehicles, services, promoCode, organization) => {
        let priceWithoutDiscount = 0, duration = 0, price = 0
        if (selectedVehicles.find(item => !item.vehicleType)) return { priceWithoutDiscount: 0, duration: 0, price: 0 }

        selectedVehicles.forEach((vehicle, index) => {
            const vehicleType = services.find(item => item._id === vehicle.services?.[0])
            const isLast = selectedVehicles.length - 1 === index
            priceWithoutDiscount += vehicleType?.price || 0
            duration += vehicleType?.duration || 0
            if (!isLast) { duration += vehicleType?.breakAfter || 0 }
        })

        price = priceWithoutDiscount

        if (promoCode) {
            if (promoCode.discountInPercent) { price = priceWithoutDiscount - (priceWithoutDiscount * promoCode.discountAmount) }
            else { price = priceWithoutDiscount - promoCode.discountAmount }
            price = Number(price.toFixed(2))
        }
        if (organization) { price = 0 }

        return { priceWithoutDiscount, duration, price }
    }

    const formatClientVehicles = (payload) => payload.map(({ carMake, licensePlate, vehicleType, service }) => ({
        carMake,
        licensePlate,
        vehicleType: vehicleType.value || vehicleType,
        services: [service.value || service]
    }))

    const formatPayload = (payload) => {
        console.log(payload)
        payload.reservationType = payload.reservationType.value
        payload.cartGroup = payload.reservationType === 'stationary' ? payload.cartGroup.value : undefined
        payload.reservationStart = payload.reservationStart.value
        payload.clientVehicles = formatClientVehicles(payload.clientVehicles)
        if (payload.clientInfo.phoneNumber) payload.clientInfo.phoneNumber = payload.clientInfo.phoneNumber.value
        if(payload.clientInfo.email) payload.clientInfo.email = payload.clientInfo.email.email
        if (!_id) payload.paymentMethod = 'manual'
        payload.discountCampaign = payload?.discountCampaign?._id

        return { ...omit(payload, ['carMake', 'licensePlate', 'vehicleType', 'service', 'vehicle', 'date']) }
    }

    const handleCreate = (payload) => {
        if (reservationType === 'mobile' && (!payload.location || !payload.city)) setError('address', { type: "custom", message: "Missing coordinates" })
        else emit({ action: 'reservation/create', payload: formatPayload(payload), startLoading: true, onSuccess: () => navigate(-1) })
    }
    const handleEdit = (payload) => {
        if (reservationType === 'mobile' && (!payload.location || !payload.city)) setError('address', { type: "custom", message: "Missing coordinates" })
        else emit({
            action: 'reservation/employeeCancel',
            payload: { _id, isEdit: true },
            startLoading: true,
            onSuccess: () => emit({
                action: 'reservation/create',
                payload: { ...formatPayload(payload), reservationToDelete: _id },
                startLoading: true,
                onSuccess: () => navigate(-2)
            })
        })
        // else console.log(formatPayload(payload))
    }
    const handleDelete = () => Alerts.confirm({
        title: t('shared.warning'),
        text: t('reservation.deleteReservation'),
        cancelButtonText: t('shared.cancel'),
        confirmButtonText: t('shared.delete'),
        onSuccess: () => emit({
            action: 'reservation/employeeCancel',
            payload: { _id },
            startLoading: true,
            onSuccess: () => navigate(-1)
        })
    })

    const loadClientsOptionsByPhoneNumber = (search) => new Promise((resolve, reject) => {
        try {
            emit({
                action: 'client/find', payload: { phoneNumber: search }, onSuccess: ({ clients }) => {
                    const options = clients.map(({ fullName, phoneNumber, source,email }) => ({ value: phoneNumber, label: phoneNumber, fullName, source, email }))
                    return resolve(options)
                }
            })
        } catch (error) { return reject() }
    })

    const loadClientsOptionsByEmail = (search) => new Promise((resolve, reject) => {
        try {
            emit({
                action: 'client/find', payload: { email: search }, onSuccess: ({ clients }) => {
                    const options = clients.map(({ fullName, phoneNumber, source, email }) => ({ value: phoneNumber, label: phoneNumber, fullName, source, email }))
                    return resolve(options)
                }
            })
        } catch (error) { return reject() }
    })

    console.log(errors)

    return <div className="screen-reservation-form-container">
        <div className="screen-reservation-form-inner-container col">
            <div className="screen-reservation-form-header row">
                <Buttons.Back text={`${_id ? t('shared.editing') : t('shared.creating')} ${t('reservation.reservation')}`} />
                <div className="row row-buttons">
                    {_id ? <>
                        <Buttons.Raised text={t('shared.revoke')} className="btn-delete" onClick={handleDelete} />
                        <Buttons.Raised text={t('shared.save')} onClick={handleSubmit(handleEdit)} />
                    </> : <Buttons.Raised text={t('shared.create')} onClick={handleSubmit(handleCreate)} />}
                </div>
            </div>
            <div className="screen-reservation-form-content">
                <h2>{t("reservation.generalInformation")}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t('reservation.cartType')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`reservationType`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                placeholder={t('reservation.cartTypePlaceholder')}
                                value={value}
                                onChange={(v) => {
                                    onChange(v)
                                    setValue('reservationStart', null)
                                    if (v.value === 'stationary') { unregister(['address', 'addressDescription', 'location', 'city']) }
                                    else if (v.value === 'mobile') { unregister('cartGroup') }
                                }}
                                classNamePrefix={error ? "invalid" : ''}
                                options={Object.entries(cartTypes).reduce((acc, [key, value]) => [...acc, { value: key, label: t(value) }], [])}
                                isDisabled={_id}
                            />
                            }
                        />
                    </div>
                    {reservationType === 'stationary' && <div className="col">
                        <span>{t('reservation.cartGroup')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`cartGroup`} rules={{ required: reservationType === 'stationary' }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                placeholder={t('reservation.cartGroupPlaceholder')}
                                options={cartGroups?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                value={value}
                                onChange={(value) => { onChange(value); setValue('reservationStart', null) }}
                                classNamePrefix={error ? "invalid" : ''}
                            />}
                        />
                    </div>}
                    {reservationType === 'mobile' && <>
                        <div className="col col-double">
                            <span>{t('reservation.address')} <span className="icon icon-required" /></span>
                            <Controller control={control} name={`address`} rules={{ required: true }}
                                render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Address
                                    ref={locationRef}
                                    value={value || ""}
                                    onChange={({ target: { value } }) => { onChange(value); setValue('location', undefined) }}
                                    onPlaceSelected={({ geometry }) => {
                                        const { lng, lat } = geometry?.location?.toJSON() ?? {}
                                        const sofiaAvailableDistance = getDistanceFromLatLonInKm(SOFIA_COORDS.latitude, SOFIA_COORDS.longitude, lat, lng)
                                        const plovdivAvailableDistance = getDistanceFromLatLonInKm(PLOVDIV_COORDS.latitude, PLOVDIV_COORDS.longitude, lat, lng)

                                        if (sofiaAvailableDistance <= 13 || plovdivAvailableDistance <= 13) {
                                            setValue('address', locationRef.current.value)
                                            setValue('location', geometry ? { type: 'Point', coordinates: [lng, lat] } : undefined)

                                            if (sofiaAvailableDistance <= 13) setValue('city', 'София')
                                            else if (plovdivAvailableDistance <= 13) setValue('city', 'Пловдив')

                                            if (geometry) clearErrors('address')
                                            else setError('address', { type: "custom", message: "Missing coordinates" })

                                        } else {
                                            Alerts.error({ title: t('reservation.invalidAddress'), text: t('reservation.addressOutsideOfZone') });
                                            setValue('address', '')
                                            setValue('location', undefined)
                                        }
                                    }}
                                    className={error ? "invalid" : ''}
                                />}
                            />
                        </div>
                        <div className="col col-double">
                            <span>{t('reservation.addressDescription')}<span className="icon icon-required" /></span>
                            <Inputs.Text
                                className={errors?.addressDescription ? "invalid" : ''}
                                {...register("addressDescription", { required: true, })}
                            />
                        </div>
                    </>}
                </div>
                <div className="divider" />
                <h2>{t('reservation.vehicleInformation')}</h2>
                {fields.map((field, index) => <div key={field.id} className="row row-vehicle">
                    <div className="col">
                        <span>{t('reservation.vehicleType')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`clientVehicles.${index}.vehicleType`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                placeholder={t('reservation.vehicleTypePlaceholder')}
                                options={vehicleTypes?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                value={value}
                                onChange={(value) => { onChange(value); setValue(`clientVehicles.${index}.service`, null); setValue('reservationStart', null) }}
                                classNamePrefix={error ? "invalid" : ''}
                                isDisabled={["card", "googlePay", "applePay"].includes(watch("selectedPaymentMethod"))}
                            />}
                        />
                    </div>
                    <div className="col">
                        <span>{t('reservation.service')} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`clientVehicles.${index}.service`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) =>
                                <Inputs.Dropdown
                                    placeholder={t('reservation.servicePlaceholder')}
                                    options={services
                                        ?.filter((service) => service.cartTypes.includes(reservationType) && service.vehicleType === clientVehicles?.[index]?.vehicleType?.value)
                                        ?.map(({ _id, name }) => ({ value: _id, label: name }))}
                                    value={value}
                                    onChange={(value) => { onChange(value); setValue('reservationStart', null) }}
                                    classNamePrefix={error ? "invalid" : ''}
                                    isDisabled={["card", "googlePay", "applePay"].includes(watch("selectedPaymentMethod"))}
                                />
                            }
                        />
                    </div>
                    <div className="col">
                        <span>{t('reservation.licensePlate')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            key={field.id}
                            className={errors.clientVehicles?.[index]?.licensePlate ? "invalid" : ''}
                            {...register(`clientVehicles.${index}.licensePlate`, { required: true, })}
                        />
                    </div>
                    <div className="col">
                        <span>{t('reservation.carMake')}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            key={field.id}
                            className={errors.clientVehicles?.[index]?.carMake ? "invalid" : ''}
                            {...register(`clientVehicles.${index}.carMake`, { required: true, })}
                        />
                    </div>
                    {index > 0 && <div className="icon icon-remove" onClick={() => remove(index)} />}
                </div>)}
                {!["card", "googlePay", "applePay"].includes(watch("selectedPaymentMethod")) && <div className="row row-add-vehicle" onClick={() => append({})}><div className="icon icon-plus" />{t('reservation.addVehicle')}</div>}
                <div className="divider" />
                <h2>{t("reservation.generalInformation")}</h2>
                <div className="row">
                    <div className="col col-half">
                        <span>{t("reservation.date")} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`date`} rules={{ required: true }}
                            render={({ field: { onChange, value } }) => <Inputs.Datepicker
                                value={value ?? null}
                                className={errors?.date ? "invalid" : ''}
                                onChange={(value) => { onChange(value); setValue('reservationStart', null) }}
                                minDate={new Date()}
                            />}
                        />
                    </div>
                    <div className="col col-half">
                        <span>{t("reservation.startTime")} <span className="icon icon-required" /></span>
                        <Controller control={control} name={`reservationStart`} rules={{ required: true }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                placeholder='--:--'
                                options={slots?.map((slot) => ({ value: slot, label: moment(slot).format('HH:mm') }))}
                                value={value}
                                onChange={onChange}
                                classNamePrefix={error ? "invalid" : ''}
                            />}
                        />
                    </div>
                    <div className="col col-half">
                        <span>{t("reservation.price")}</span>
                        <Inputs.Text
                            label={t("shared.lv")}
                            className={errors?.price ? "invalid" : ''}
                            {...register("price", { required: true, })}
                        />
                    </div>
                </div>
                <div className="divider" />
                <h2>{t("reservation.clientInformation")}</h2>
                <div className="row">
                    <div className="col">
                        <span>{t("reservation.phoneNumber")}</span>
                        <Controller control={control} name={`clientInfo.phoneNumber`} rules={{ validate: (value) => !value || /^[+]?$|(^[+]{1}[0-9]{12}$)/.test(value.value) }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                async
                                placeholder="+359"
                                cacheOptions
                                defaultOptions
                                loadOptions={loadClientsOptionsByPhoneNumber}
                                value={value}
                                // isDisabled={_id}
                                isClearable
                                formatCreateLabel={(value) => value}
                                formatOptionLabel={({ label, fullName, source }) => [label, fullName, source === 'geoWash' ? 'M' : source === 'amarant' ? 'A' : 'Р'].join(" | ")}
                                classNamePrefix={error ? "invalid" : ''}
                                createOptionPosition="first"
                                components={{
                                    SingleValue: props => <components.SingleValue {...props}>{props.children.split(" | ")[0]}</components.SingleValue>,
                                    Option: props => <components.Option {...props}>{props.data.__isNew__ ? props.children.split(" | ")[0] : props.children}</components.Option>
                                }}
                                onChange={(value, { action }) => {
                                    onChange(value)
                                    if (!value?.__isNew__ && action !== 'clear') { 
                                        if(!watch("clientInfo.fullName") || !watch("clientInfo.fullName").length){
                                            setValue('clientInfo.fullName', value.fullName);
                                            trigger('clientInfo.fullName')
                                        }
                                        // setValue('clientInfo.email', value.email);
                                        // trigger('clientInfo.email')
                                    }
                                }}
                            />}
                        />
                    </div>
                    <div className="col">
                        <span>{t('employee.email')}</span>
                        {/* <Inputs.Text
                            className={errors?.email ? "invalid" : ''}
                            {...register(`clientInfo.email`)}
                        /> */}
                           <Controller control={control} name={`clientInfo.email`} render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.Dropdown
                                async
                                placeholder="Имейл"
                                cacheOptions
                                defaultOptions
                                loadOptions={loadClientsOptionsByEmail}
                                value={value}
                                // isDisabled={_id}
                                isClearable
                                formatCreateLabel={(value) => value}
                                formatOptionLabel={({ email, fullName, source }) => {
                                    return [email, fullName, source === 'geoWash' ? 'M' : source === 'amarant' ? 'A' : 'Р'].join(" | ")
                                }}
                                classNamePrefix={error ? "invalid" : ''}
                                createOptionPosition="first"
                                components={{
                                    SingleValue: props => <components.SingleValue {...props}>{props.children.split(" | ")[0]}</components.SingleValue>,
                                    Option: props => <components.Option {...props}>{props.data.__isNew__ ? props.children.split(" | ")[0] : props.children}</components.Option>
                                }}
                                onChange={(value, { action }) => {
                                    onChange({...value, value: value.email})
                                    if (!value?.__isNew__ && action !== 'clear') { 
                                        if(!watch("clientInfo.fullName") || !watch("clientInfo.fullName").length){
                                            setValue('clientInfo.fullName', value.fullName);
                                            trigger('clientInfo.fullName')
                                        }
                                        // setValue('clientInfo.phoneNumber', value.value);
                                        // trigger('clientInfo.phoneNumber')
                                    }
                                }}
                            />}
                        />
                    </div>
                    <div className="col">
                        <span>{t("reservation.fullName")}<span className="icon icon-required" /></span>
                        <Inputs.Text
                            // disabled={_id}
                            className={errors?.clientInfo?.fullName ? "invalid" : ''}
                            {...register("clientInfo.fullName", { required: true })}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ReservationForm